import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "you-can-contribute-to-the-content-on-the-carbon-site-in-a-few-different-ways-choose-the-way-that-works-best-depending-on-how-extensive-your-contribution-is-and-be-sure-to-read-our-content-writing-guidelines-before-you-begin"
    }}>{`You can contribute to the content on the Carbon site in a few different ways. Choose the way that works best, depending on how extensive your contribution is. And be sure to read our content writing guidelines before you begin!`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Editing pages</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Making regular content updates</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content and style guidelines</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "editing-pages"
    }}>{`Editing pages`}</h2>
    <p>{`The easiest way to edit a page is with the `}<inlineCode parentName="p">{`Edit this page on Github`}</inlineCode>{` link that appears at the bottom of every page on the site. The link opens the specific Github page where you can edit the content and then propose the change with a pull request.`}</p>
    <p>{`Alternatively, if you have a specific fix or contribution, you can fork the repo and generate a `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Creating-a-PR-through-github.com-UI"
      }}>{`pull request`}</a>{`.`}</p>
    <h2 {...{
      "id": "making-regular-content-updates"
    }}>{`Making regular content updates`}</h2>
    <p>{`If you are planning regular or more comprehensive content updates, you’ll want to fork the repo and install some of the tools we use to build the website. This will create an easier workflow for you long term.`}</p>
    <p>{`Instructions for how to `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Updating-content-on-carbondesignsystem.com"
      }}>{`install tools`}</a>{` and fork the repo.`}</p>
    <p>{`Instructions for how to `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Update-content-with-VSCode"
      }}>{`update content with VSCode`}</a>{`.`}</p>
    <h2 {...{
      "id": "content-and-style-guidelines"
    }}>{`Content and style guidelines`}</h2>
    <p>{`Before you get started, take a moment to review our content guidelines. Following these will help us ensure consistency of experience across the site.`}</p>
    <h3 {...{
      "id": "use-markdown"
    }}>{`Use Markdown`}</h3>
    <p>{`The website is written in `}<a parentName="p" {...{
        "href": "https://gatsby-theme-carbon.now.sh/components/markdown/"
      }}>{`Markdown`}</a>{`, which makes it easy for anyone to contribute content in a systematic way. If you don’t already have a Markdown editor installed, there are plenty of free editors available online.`}</p>
    <p>{`Check out Carbon’s handy markdown `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Markdown-cheatsheet"
      }}>{`styling cheatsheet`}</a>{` for how to style content.`}</p>
    <h3 {...{
      "id": "guidelines-for-writing-content"
    }}>{`Guidelines for writing content`}</h3>
    <p>{`Please keep these things in mind when writing content for Carbon.`}</p>
    <ul>
      <li parentName="ul">{`Aim for a friendly and encouraging tone.`}</li>
      <li parentName="ul">{`Speak directly to the user. You can use the second person pronoun (“you”).`}</li>
      <li parentName="ul">{`Keep sentences and paragraphs short and focused.`}</li>
      <li parentName="ul">{`Be clear and concise by removing unnecessary words. The more concise the text, the easier it is for all users to understand.`}</li>
      <li parentName="ul">{`Use sentence case for everything, including component names, e.g. Content switcher and Data table.`}</li>
    </ul>
    <p>{`To learn about writing content for component pages, see our `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Production-guidelines#creating-content-for-component-pages"
      }}>{`production guidelines`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      